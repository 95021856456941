.raise:hover,
.raise:focus {
  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
  -webkit-transform: translateY(-0.25em);
          transform: translateY(-0.25em);
}

.raise {
  --color: #ffa260;
  --hover: #e5ff60;
}

.button {
  color: var(--color);
  transition: 0.25s;
}
.button:hover, .button:focus {
  border-color: var(--hover);
  color: #fff;
}
