.col-card {
  margin-top: 30px;
}

/* 
.col-card:hover {
  -webkit-animation: swing 1s ease;
  animation: swing 1s ease;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
} */
